/* CodeMirror */

.CodeMirror {
  font-size: $default-font-size;
  font-family: $type1-regular;
  height: auto;
  text-align: left;
  min-height: auto;
}
.CodeMirror-scroll {
  min-height: auto;
}
