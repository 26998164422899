/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  >.btn {
    @extend .mt-3;
    @extend .me-2;
  }

  .slider-wrap {
    height: 100px;
  }

  >.btn-toolbar {
    @extend .mt-3;
    @extend .me-3;
  }

  >.btn-group,
  >.btn-group-vertical {
    @extend .mt-2;
    @extend .me-2;
  }

  .progress {
    margin-top: 1.5rem;
  }

  .circle-progress {
    @extend .mt-2;
  }

  >h2,
  >h3,
  >h4,
  >h5,
  >h6,
  >h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  .ul-slider {
    &.noUi-horizontal {
      margin-top: 2rem;
    }

    &.noUi-vertical {
      margin-right: 2rem;
    }
  }

  >.dropdown {
    display: inline-block;
    @extend .me-2;
    margin-bottom: 0.5rem;
  }

  nav {
    .breadcrumb {
      margin-bottom: 1.375rem;
    }

    &:last-child {
      .breadcrumb {
        margin-bottom: 0;
      }
    }
  }

  .editable-form {
    >.form-group {
      border-bottom: 1px solid $border-color;
      padding-bottom: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }

  .circle-progress {
    padding: 15px;
  }

  .circle-progress-block {
    @extend .mb-3;
    @extend .px-2;
  }
}

.demo-modal {
  position: static;
  display: block;

  .modal-dialog {
    @extend .mt-0;

    &.modal-lg {
      max-width: 100%;
    }
  }
}

.loader-demo-box {
  @extend .border;
  @extend .border-secondary;
  @extend .rounded;
  width: 100%;
  height: 200px;
  position: relative;
  @extend .d-flex;
  @extend .align-items-center;
}

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px;
}

.rounded-legend {
  ul {
    .rtl & {
      padding-right: 0;
    }

    li {
      list-style-type: none;
      color: $text-muted;
      font-size: .75rem;

      .legend-dots {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .5rem;

        .rtl & {
          margin-left: .5rem;
        }
      }
    }
  }

  &.legend-horizontal {
    @include display-flex;

    ul {
      li {
        display: inline-block;
        margin-right: 1.5rem;

        .rtl & {
          margin-right: auto;
          margin-left: 1.5rem;
        }
      }
    }
  }

  &.legend-top-right {
    ul {
      float: right;

      .rtl & {
        float: left;
      }
    }
  }

  &.legend-vertical {
    ul {
      li {
        margin-top: 1rem;
      }
    }
  }
}

#proBanner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #000;
  padding: 0 2.25rem;

  .card-body-padding {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-top: .55rem;
    padding-bottom: .55rem;

    @media (max-width: 766px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .buy-now-text {
    color: $white;

    @media (max-width: 766px) {
      font-size: 10px;
      line-height: 1.5;
    }
  }

  .btn {
    &.buy-now-btn {
      background: transparent;
      color: #88b6ff;
      border-radius: 10px;
      // padding: 0.625rem 1.25rem;
      padding: 1rem 0;
      border-radius: 4px;
      font-weight: bold;

      @media (max-width: 766px) {
        min-width: 80px;
        padding: 1rem .5rem;
        font-size: 12px;
      }
    }

    &#bannerClose {
      i {
        margin-right: 1rem;
        font-size: 1.5rem;

        @media (max-width: 766px) {
          font-size: 1rem;
          margin-right: 0;
        }
      }
    }
  }

  a {
    text-decoration: none;

    i {
      font-size: 1.5rem;

      @media (max-width: 766px) {
        font-size: 1rem;
      }
    }
  }

}

.proBanner-padding-top {
  padding-top: $buy-nowbanner-padding-top !important;
}
