// BlockQuote Mixins //

@mixin blockquote($color) {
  border-color: $color;

  .blockquote-footer {
    background: transparent;
    color: $color;
  }
}

@mixin alert-variant($color) {
  color: $color;
}

@mixin alert-variant-fill($color) {
  color: lighten($color, 80%);
  background: $color;
}